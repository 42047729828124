import { Grid, Paper } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { NoticeRunner, Partnership } from 'components';
import { Chess } from 'components/icons';
import { gamesService } from 'services';
import { ListTopWithdraw, SliceBanner } from './components';

const Home = () => {
  const { data: home } = useQuery(['gamesService.openBanner'], () => gamesService.openBanner());

  return (
    <div>
      <div className='-container'>
        <SliceBanner />
        <NoticeRunner />
      </div>

      <div className='my-6'>
        <Paper className='h-[40px] flex items-center justify-between px-3 border-l-2 border-primary-dark'>
          <div>Giới thiệu</div>
          <Chess className='text-transparent' />
        </Paper>
        <Paper className='mt-2 px-3 py-2'>
          Được thành lập từ năm 2006, tập đoàn NOVAON hiện đang hoạt động chủ yếu ở ba lĩnh vực Tiếp thị số (Digital
          Marketing), Chuyển đổi số (Digital Transformation) và Thương mại điện tử (Ecommerce). Với 17 năm trải qua
          nhiều biến động và thăng trầm, đến nay, Novaon đã được tin chọn bởi 95.000 khách hàng doanh nghiệp. Đồng thời,
          Novaon cũng khẳng định vị thế là một trong những thương hiệu hàng đầu trong nền kinh tế số Việt Nam; giữ thị
          phần Digital Marketing số 1 tại Việt Nam và khu vực Đông Nam Á. Trên đà tăng trưởng và phát triển, hiện nay,
          Novaon sở hữu hệ sinh thái số với 8 nền tảng công nghệ “Make in Vietnam”, lọt vào TOP500 doanh nghiệp tăng
          trưởng xuất sắc nhất Việt Nam (FAST500) trong 5 năm liên tiếp (2019, 2020, 2021, 2022, 2023). Ngoài ra, Tập
          đoàn Novaon còn vinh dự là một trong 12 doanh nghiệp nền tảng số được Phó Thủ tướng Chính phủ Vũ Đức Đam vinh
          danh năm 2020. Đặt mục tiêu thực hiện cuộc cách mạng số cho nền kinh tế Việt Nam, Novaon không ngừng cải tiến
          và mở rộng các sản phẩm, dịch vụ – khẳng định vị thế tại Việt Nam và hứa hẹn vươn xa trên thị trường quốc tế.
          Với sứ mệnh đồng hành cùng hàng triệu doanh nghiệp chuyển đổi số, Tập đoàn Novaon luôn tiên phong đón đầu xu
          hướng để mạnh mẽ bứt phá và kiến tạo nên những giá trị tốt nhất cho khách hàng.
        </Paper>
      </div>

      <div className='my-6'>
        <Paper className='h-[40px] flex items-center px-3 border-l-2 border-primary-dark'>
          <div>Thống kê</div>
        </Paper>
        <Paper className='mt-2 px-3 py-3'>
          <Grid container spacing={1.5}>
            {[
              { label: 'Số lượng đối tác', value: home?.numberOfSupplier },
              { label: 'Thành viên', value: home?.numberOfMember },
              { label: 'Cộng tác viên', value: home?.numberOfCollaborators },
              { label: 'Khách hàng tiềm năng', value: home?.potentialCustomers },
            ].map((item, index) => (
              <Grid item key={index} xs={6}>
                <div>
                  <div className='text-lg font-bold'>{item.value ?? 0}</div>
                  <div className='text-sm max-line-1 text-primary-light'>{item.label}</div>
                </div>
              </Grid>
            ))}
          </Grid>
        </Paper>
      </div>

      <div className='hidden my-6'>
        <ListTopWithdraw />
      </div>
      <div className='my-6'>
        <Partnership isHome />
      </div>
    </div>
  );
};

export default Home;
