import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profileSlice';
import { formatBalance } from 'utils/common';

const Balance = () => {
  const { balance } = useSelector(profileSelector);
  const [showBalance, setShowBalance] = useState(false);

  return (
    <div className='flex items-center relative gap-2 my-2'>
      <span className='text-[42px] leading-[48px] font-bold'>
        {formatBalance(balance)
          .split('')
          .map((number, index) => (
            <span key={index}>{showBalance ? number : number === ',' ? ' ' : '*'}</span>
          ))}
      </span>
      <IconButton className='absolute right-[-12px] text-white' onClick={() => setShowBalance((prev) => !prev)}>
        {showBalance ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
      </IconButton>
    </div>
  );
};

export default Balance;
