import { ArrowBack } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { AppBar, Autocomplete, Container, FormControl, IconButton, TextField } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { privateRoute } from 'routes';
import { authService, queryClient } from 'services';
import BankList from './BankList.json';

const Banking = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { control, handleSubmit, setValue } = useForm({ mode: 'onChange' });

  const { data: profile } = useQuery(['authService.getProfile'], () => authService.getProfile());

  const { mutate: updateProfile, isLoading } = useMutation(authService.updateProfile, {
    onSuccess: () => {
      queryClient.invalidateQueries(['authService.getProfile']);
      enqueueSnackbar('Đã cập nhật thông tin');
      navigate(-1);
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      updateProfile({
        ...(values as UpdateProfileBody),
        id: profile?.id!,
      });
    })();
  };

  return (
    <div className='pt-20'>
      <AppBar
        component={Container}
        elevation={1}
        className='shadow-md inset-0 bottom-[unset] h-[54px] flex flex-row items-center gap-1 px-2'
      >
        <Link to={privateRoute.account.path}>
          <IconButton color='inherit'>
            <ArrowBack />
          </IconButton>
        </Link>
        <span className='text-lg font-medium'>Liên kết ngân hàng</span>
      </AppBar>

      <div className='space-y-8 pb-6'>
        <Controller
          control={control}
          name='bankName'
          defaultValue={profile?.bankName ?? null}
          rules={{
            required: 'Ngân hàng không được để trống',
          }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <FormControl fullWidth>
              <label>Ngân hàng</label>
              <Autocomplete
                freeSolo
                value={value}
                onInputChange={(event, value) => {
                  onChange({ target: { value } });
                  setValue('bankName', value);
                }}
                onChange={(event, option) => {
                  onChange({ target: { value: option?.label ?? option } });
                  setValue('bankName', option?.label ?? option);
                }}
                options={BankList.banks}
                getOptionLabel={(option) => option.label ?? option}
                isOptionEqualToValue={(option, value) => option === value || option.label === value}
                classes={{
                  option: 'text-sm min-h-[40px]',
                }}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    sx={{
                      '& .MuiInputBase-root': {
                        color: 'var(--color-text-main)',
                        background: '#FFFC',
                      },
                    }}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name='bankAccountNumber'
          defaultValue={profile?.bankAccountNumber ?? ''}
          rules={{
            required: 'Số tài khoản không được để trống',
          }}
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth>
              <label>Số tài khoản</label>
              <TextField
                {...field}
                sx={{
                  '& .MuiInputBase-root': {
                    color: 'var(--color-text-main)',
                    background: '#FFFC',
                  },
                }}
                error={!!error}
                helperText={error?.message}
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name='bankAccountHolder'
          defaultValue={profile?.bankAccountHolder ?? ''}
          rules={{
            required: 'Chủ tài khoản không được để trống',
          }}
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth>
              <label>Chủ tài khoản</label>
              <TextField
                {...field}
                sx={{
                  '& .MuiInputBase-root': {
                    color: 'var(--color-text-main)',
                    background: '#FFFC',
                  },
                }}
                error={!!error}
                helperText={error?.message}
              />
            </FormControl>
          )}
        />

        <div>
          <div className='text-sm'>* Quý khách vui lòng điền đúng thông tin</div>
        </div>
        <div>
          <LoadingButton
            fullWidth
            size='large'
            variant='contained'
            color='inherit'
            loading={isLoading}
            onClick={handleClickSubmit}
          >
            Liên kết ngân hàng
          </LoadingButton>
        </div>

        <div>
          <img src={require('assets/images/Group.Banks.B1.png')} />
        </div>
        <div>
          <img src={require('assets/images/Group.Banks.B2.png')} className='h-[48px] mx-auto' />
        </div>
      </div>
    </div>
  );
};

export default Banking;
