import { Facebook, Instagram, Telegram, YouTube } from '@mui/icons-material';
import { IconButton, Paper } from '@mui/material';

interface Props {
  isHome?: boolean;
}

const Partnership = ({ isHome }: Props) => {
  return (
    <>
      <Paper className='h-[40px] flex items-center px-3 border-l-2 border-primary-dark'>
        <div>Đối tác lớn</div>
      </Paper>
      <Paper className='my-2 p-3'>
        <img src={require('assets/images/Group.Partners.B1.png')} />
      </Paper>

      {isHome && (
        <>
          <div className='my-6'>
            <Paper className='h-[40px] flex items-center justify-between px-3 border-l-2 border-primary-dark'>
              <div className='font-medium text-color-primary'>Về chúng tôi</div>
            </Paper>
            <Paper className='mt-2 px-3 py-2'>
              Công ty NOVAON Groups chúng tôi đồng hành cùng khách hàng trong việc xác định các cơ hội số, khám phá các
              giá trị mới và tạo ra các mô hình kinh doanh mới, thông qua tận dụng những tiềm năng từ công nghệ.
            </Paper>
          </div>

          <div className='my-6'>
            <Paper className='h-[40px] flex items-center px-3 border-l-2 border-primary-dark'>
              <div className='font-medium text-color-primary'>Trụ sở chính</div>
            </Paper>
            <Paper className='my-2 p-3'>
              <div>ADD: Open 24 hours Floor 8 Paseo de Roxas, Legazpi Village, Makati, Metro Manila, Philippines</div>
              <div className='mt-3'>Người nhận: Bộ phận pháp lý</div>
              <div>12 Marina Blvd, #05-01, 7VH3 & JQ, Singapore 018982</div>
              <div className='mt-3'>Chi nhánh: Metro@V49manila</div>
              <div className='flex justify-center gap-1 mt-3 -mb-1 pt-1 border-t border-black/20'>
                <IconButton>
                  <Facebook fontSize='medium' />
                </IconButton>
                <IconButton>
                  <Instagram fontSize='medium' />
                </IconButton>
                <IconButton>
                  <Telegram fontSize='medium' />
                </IconButton>
                <IconButton onClick={() => window.open('https://youtube.com')}>
                  <YouTube fontSize='medium' />
                </IconButton>
              </div>
            </Paper>
          </div>
        </>
      )}

      <div className='mt-6'>
        <img src={require('assets/images/Group.Partners.B2.png')} className='h-[48px] mx-auto' />
      </div>
      <div className='mt-6'>
        <img src={require('assets/images/Group.App.png')} />
      </div>
    </>
  );
};

export default Partnership;
