import {
  AccountBalanceOutlined,
  AccountBalanceWalletOutlined,
  AccountCircleOutlined,
  Add,
  HistoryOutlined,
  LockResetOutlined,
  LogoutOutlined,
  NavigateNext,
  PrintOutlined,
  Remove,
} from '@mui/icons-material';
import { Dialog, ListItemText, MenuItem, MenuList, styled } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { NoticeRunner } from 'components';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { profileSelector, saveProfile, signOut } from 'reducers/profileSlice';
import { privateRoute } from 'routes';
import { authService } from 'services';
import {
  Balance,
  PopupDepositHelp,
  PopupListDeposit,
  PopupListStats,
  PopupListWithdraw,
  PopupPasswordHelp,
} from './components';

const StyledMenuItem = styled(MenuItem)({
  borderRadius: 8,
  padding: '8px 12px',
  background: '#FFFC',
  color: 'var(--color-text-main)',
  '&:hover': {
    background: '#FFF',
  },
  '& svg': {
    fontSize: 20,
  },
  '& .MuiListItemText-root': {
    marginLeft: 8,
  },
});

export const InternetBanking = () => (
  <Link to={privateRoute.banking.path}>
    <StyledMenuItem>
      <AccountBalanceOutlined />
      <ListItemText>Liên kết ngân hàng</ListItemText>
      <NavigateNext />
    </StyledMenuItem>
  </Link>
);

const Account = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { incId } = useSelector(profileSelector);

  const [openStats, setOpenStats] = useState(false);
  const [openDeposit, setOpenDeposit] = useState(false);
  const [openWithdraw, setOpenWithdraw] = useState(false);
  const [openHelp, setOpenHelp] = useState(false);
  const [openPassword, setOpenPassword] = useState(false);

  useQuery(['authService.getProfile'], () => authService.getProfile(), {
    onSuccess: (profile) => {
      dispatch(saveProfile(profile));
    },
  });

  return (
    <div>
      <div
        style={{ background: `url(${require('assets/images/Cover.Account.png')}) no-repeat top left / cover` }}
        className='-container sm:h-[180px] h-[140px] flex items-center justify-between pl-4 pr-6'
      >
        <div className='w-[200px] text-center sm:text-2xl text-xl font-bold whitespace-pre-line'>
          <div style={{ textShadow: '#FF8 1px 0 10px', color: '#FFFC' }}>
            {`TÀI KHOẢN`}
            <div>ID: {incId}</div>
          </div>
        </div>
      </div>
      <div className='-container'>
        <NoticeRunner />
      </div>

      <div className='py-3'>
        <div className='bg-primary-dark shadow-xl text-white rounded-[12px] px-4 py-3'>
          <div className='mb-6'>Số dư tài khoản</div>
          <Balance />
        </div>
        <div className='flex space-x-3 py-2'>
          <StyledMenuItem className='w-full flex justify-center gap-2' onClick={() => setOpenHelp(true)}>
            <Add />
            <span>Nạp điểm</span>
          </StyledMenuItem>
          <StyledMenuItem
            className='w-full flex justify-center gap-2'
            onClick={() => navigate(privateRoute.exchange.path)}
          >
            <Remove />
            <span>Quy đổi</span>
          </StyledMenuItem>

          <Dialog open={openHelp} onClose={() => setOpenHelp(false)}>
            <PopupDepositHelp onClose={() => setOpenHelp(false)} />
          </Dialog>
          <Dialog open={openPassword} onClose={() => setOpenPassword(false)}>
            <PopupPasswordHelp onClose={() => setOpenPassword(false)} />
          </Dialog>
        </div>
      </div>

      <MenuList className='flex flex-col space-y-2 mb-3'>
        <Link to={privateRoute.profile.path}>
          <StyledMenuItem>
            <AccountCircleOutlined />
            <ListItemText>Thông tin cá nhân</ListItemText>
            <NavigateNext />
          </StyledMenuItem>
        </Link>
        <Link to={privateRoute.banking.path}>
          <StyledMenuItem>
            <AccountBalanceOutlined />
            <ListItemText>Liên kết ngân hàng</ListItemText>
            <NavigateNext />
          </StyledMenuItem>
        </Link>

        <StyledMenuItem onClick={() => setOpenStats(true)}>
          <HistoryOutlined />
          <ListItemText>Lịch sử tham gia</ListItemText>
          <NavigateNext />
        </StyledMenuItem>
        <StyledMenuItem onClick={() => setOpenDeposit(true)}>
          <AccountBalanceWalletOutlined />
          <ListItemText>Lịch sử nạp điểm</ListItemText>
          <NavigateNext />
        </StyledMenuItem>
        <StyledMenuItem onClick={() => setOpenWithdraw(true)}>
          <PrintOutlined />
          <ListItemText>Lịch sử quy đổi</ListItemText>
          <NavigateNext />
        </StyledMenuItem>

        <Link to={privateRoute.password.path}>
          <StyledMenuItem>
            <LockResetOutlined />
            <ListItemText>Đổi mật khẩu</ListItemText>
            <NavigateNext />
          </StyledMenuItem>
        </Link>
        <StyledMenuItem onClick={() => dispatch(signOut({}))}>
          <LogoutOutlined />
          <ListItemText>Đăng xuất</ListItemText>
          <NavigateNext />
        </StyledMenuItem>
      </MenuList>

      <Dialog open={openStats} onClose={() => setOpenStats(false)} fullScreen>
        <PopupListStats onClose={() => setOpenStats(false)} />
      </Dialog>
      <Dialog open={openDeposit} onClose={() => setOpenDeposit(false)} fullScreen>
        <PopupListDeposit onClose={() => setOpenDeposit(false)} />
      </Dialog>
      <Dialog open={openWithdraw} onClose={() => setOpenWithdraw(false)} fullScreen>
        <PopupListWithdraw onClose={() => setOpenWithdraw(false)} />
      </Dialog>
    </div>
  );
};

export default Account;
