import { ArrowBack } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { AppBar, Container, FormControl, IconButton } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { InputPassword } from 'components/common';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { privateRoute } from 'routes';
import { authService } from 'services';

const ScreenPassword = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { control, handleSubmit, watch } = useForm({ mode: 'onChange' });
  const { newPassword } = watch();

  const { mutate: updatePassword, isLoading } = useMutation(authService.updatePassword, {
    onSuccess: () => {
      enqueueSnackbar('Đã đổi mật khẩu');
      navigate(-1);
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      updatePassword({
        ...(values as UpdatePasswordBody),
      });
    })();
  };

  return (
    <div className='pt-20'>
      <AppBar
        component={Container}
        elevation={1}
        className='shadow-md inset-0 bottom-[unset] h-[54px] flex flex-row items-center gap-1 px-2'
      >
        <Link to={privateRoute.account.path}>
          <IconButton color='inherit'>
            <ArrowBack />
          </IconButton>
        </Link>
        <span className='text-lg font-medium'>Đổi mật khẩu</span>
      </AppBar>

      <div className='space-y-8'>
        <Controller
          name='oldPassword'
          defaultValue=''
          control={control}
          rules={{
            required: 'Mật khẩu không được để trống',
            minLength: { value: 6, message: 'Mật khẩu có ít nhất 6 ký tự' },
          }}
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth>
              <label>Mật khẩu cũ</label>
              <InputPassword
                {...field}
                sx={{
                  '& .MuiInputBase-root': {
                    color: 'var(--color-text-main)',
                    background: '#FFFC',
                  },
                }}
                error={!!error}
                helperText={error?.message}
              />
            </FormControl>
          )}
        />
        <Controller
          name='newPassword'
          defaultValue=''
          control={control}
          rules={{
            required: 'Mật khẩu không được để trống',
            minLength: { value: 6, message: 'Mật khẩu có ít nhất 6 ký tự' },
          }}
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth>
              <label>Mật khẩu mới</label>
              <InputPassword
                {...field}
                sx={{
                  '& .MuiInputBase-root': {
                    color: 'var(--color-text-main)',
                    background: '#FFFC',
                  },
                }}
                error={!!error}
                helperText={error?.message}
              />
            </FormControl>
          )}
        />
        <Controller
          name='passwordConfirm'
          defaultValue=''
          control={control}
          rules={{
            required: 'Mật khẩu không hợp lệ',
            validate: {
              match: (value) => value === newPassword || 'Mật khẩu không khớp',
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth>
              <label>Xác nhận mật khẩu</label>
              <InputPassword
                {...field}
                sx={{
                  '& .MuiInputBase-root': {
                    color: 'var(--color-text-main)',
                    background: '#FFFC',
                  },
                }}
                error={!!error}
                helperText={error?.message}
              />
            </FormControl>
          )}
        />

        <div className='pt-4'>
          <LoadingButton
            fullWidth
            size='large'
            variant='contained'
            color='inherit'
            loading={isLoading}
            onClick={handleClickSubmit}
          >
            Đổi mật khẩu
          </LoadingButton>
        </div>
      </div>
    </div>
  );
};

export default ScreenPassword;
